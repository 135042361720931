import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import loginImg from "../../assets/Login-img.png";

const Login = () => {
  const [loginInput, setLoginInput] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const loginData = {
      email: loginInput,
      password,
    };

    try {
      const response = await fetch("https://api.rezen.in/api/v1/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        localStorage.setItem("authToken", result.data.access_token);
        console.log(result.data);
        // Adjust the key based on your API response
        localStorage.setItem("user", JSON.stringify(result.data.user)); // Adjust the key based on your API response
        window.dispatchEvent(new Event("userUpdated"));

        toast.success(result.msg || "Login successful");
        navigate("/"); // Redirect to the homepage or other page
      } else {
        toast.error(result.msg || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#faf6f4] padding-x py-16">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg flex">
        {/* Left: Graphic Section */}
        <div className="hidden md:flex w-1/2 bg-[#fef4ef] justify-center items-center">
          <img
            src={loginImg}
            alt="Signup Illustration"
            className="h-full w-full"
          />
        </div>
        {/* Right: Form Section */}
        <div className="w-full md:w-1/2 px-7 sm:px-12 py-8 sm:py-11 md:px-14 lg:px-18 xl:px-20 md:py-16">
          <h1 className="text-2xl md:text-3xl font-semibold md:mb-4 mb-2 text-gray-800 text-center mt-1">
            Welcome Back!
          </h1>
          <p className="text-gray-500 mb-10 md:mb-16 text-center">
            Login to your account and continue your journey with us.
          </p>
          <form className="space-y-5 md:space-y-6" onSubmit={handleLogin}>
            <div className="space-y-2">
              <label
                className="block text-gray-700 text-sm md:text-base font-medium"
                htmlFor="loginInput"
              >
                Username or Email
              </label>
              <input
                value={loginInput}
                onChange={(e) => setLoginInput(e.target.value)}
                type="text"
                id="loginInput"
                name="loginInput"
                className="w-full px-3 sm:px-4 py-1.5 sm:py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent placeholder:text-sm"
                placeholder="Enter your username or email"
                required
              />
            </div>

            <div className="space-y-2">
              <label
                className="block text-gray-700 text-sm md:text-base font-medium"
                htmlFor="password"
              >
                Password
              </label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                name="password"
                className="w-full px-3 sm:px-4 py-1.5 sm:py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent placeholder:text-sm"
                placeholder="Enter your password"
                required
              />
            </div>

            <button
              type="submit"
              className="mt-4 w-full bg-[#f0703a] text-white py-2 md:py-2.5 rounded-lg font-semibold shadow-md hover:bg-[#cc6235] transition duration-300"
            >
              Login
            </button>
          </form>

          <p className="text-center text-gray-600 text-sm md:text-base mt-6">
            Don’t have an account?{" "}
            <Link
              to="/signup"
              className="text-[#f0703a] font-medium hover:underline"
            >
              Sign up here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
