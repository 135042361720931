import { useState, useEffect } from "react";
import logo from "../../assets/Logo.svg";
import { FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "../../index.css";
import toast, { Toaster } from "react-hot-toast";
import "../../index.css";
import { isLoggedIn, getUserDetails, logout } from "../../utility/AuthUtil";

const handleButtons = () => {
  // Displaying a custom toast notification
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              Feature Coming Soon!
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Stay tuned for updates!
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
        >
          Close
        </button>
      </div>
    </div>
  ));
};

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  let dropdownTimer = null;

  // Toggle dropdowns: only one at a time can be open
  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null); // Close if already open
    } else {
      setActiveDropdown(dropdown); // Open the clicked dropdown
    }
  };

  const handleMouseEnter = (dropdown) => {
    clearTimeout(dropdownTimer);
    setActiveDropdown(dropdown); // Open the hovered dropdown
  };

  const handleMouseLeave = () => {
    dropdownTimer = setTimeout(() => {
      setActiveDropdown(null); // Close after delay
    }, 200); // Timeout for smooth transition
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const loadUser = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    // Load user on component mount
    loadUser();

    // Listen for user updates (e.g., login/logout)
    const handleUserUpdate = () => {
      loadUser();
    };

    window.addEventListener("userUpdated", handleUserUpdate);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("userUpdated", handleUserUpdate);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    navigate("/");
    window.dispatchEvent(new Event("userUpdated")); // Notify other components of logout
  };

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
      <nav className="bg-[#fdf1db] font-sora">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="flex padding-x justify-between items-center py-6 md:py-7"
        >
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img src={logo} className="h-9 md:h-12 xl:h-14" alt="Logo" />
          </Link>

          {/* Hamburger for Mobile */}
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="relative text-xl sm:text-2xl md:text-3xl text-[#cc6235]"
            >
              {mobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Nav Links for Large Screens */}
          <div className="hidden lg:flex lg:items-center lg:space-x-3 xl:space-x-5 justify-end pl-5">
            {/* About Us Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("about")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("about")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] lg:text-sm xl:text-base font-semibold"
              >
                About Us
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "about" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "about" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out z-50">
                  <Link to="/">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Home
                    </span>
                  </Link>
                  <Link to="/team">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Team
                    </span>
                  </Link>
                  <Link to="/faq">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      FAQs
                    </span>
                  </Link>

                  <Link to="/contact-us">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Contact Us
                    </span>
                  </Link>
                </div>
              )}
            </div>

            {/* Services Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("services")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("services")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] font-semibold lg:text-sm xl:text-base"
              >
                Services
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "services" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "services" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out z-50">
                  <Link to="/depression">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Depression
                    </span>
                  </Link>
                  <Link to="/addiction">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Addiction
                    </span>
                  </Link>

                  <Link to="/anxiety">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Anxiety
                    </span>
                  </Link>
                  <Link to="/ocd">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      OCD
                    </span>
                  </Link>
                  <Link to="/women-problems">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Women Problems
                    </span>
                  </Link>
                  <Link to="/social-anxiety">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Social Anxiety
                    </span>
                  </Link>
                </div>
              )}
            </div>

            {/* Resources Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("resources")}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={() => toggleDropdown("resources")}
                className="flex items-center text-[#cc6235] hover:text-[#f0703a] lg:text-sm xl:text-base font-semibold"
              >
                Resources
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-300 ${
                    activeDropdown === "resources" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {activeDropdown === "resources" && (
                <div className="absolute bg-white shadow-lg mt-2 rounded-lg w-56 p-4 space-y-3 text-base transition-opacity duration-200 ease-in-out z-50">
                  <Link to="/all-resources">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      All Resources
                    </span>
                  </Link>
                  <Link to="/music">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Music
                    </span>
                  </Link>
                  <Link to="/videos">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Videos
                    </span>
                  </Link>
                  <Link to="/assessments">
                    <span className="block px-4 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Assessment
                    </span>
                  </Link>
                  <button onClick={handleButtons}>
                    <span className="block px-4 pb-2 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                      Exercises
                    </span>
                  </button>
                </div>
              )}
            </div>

            <Link
              to="/blogs"
              className="text-[#cc6235] hover:text-[#f0703a] font-semibold lg:text-sm xl:text-base"
            >
              Blogs
            </Link>

            <Link
              to="/chatbot"
              className="text-[#cc6235] hover:text-[#f0703a] font-semibold lg:text-sm xl:text-base"
            >
              Chatbot
            </Link>

            <div className="flex space-x-3">
              {/* Get Started Button */}

              {!user ? (
                <Link
                  to="/signup"
                  className="
      px-3 py-2 
      text-sm
      xl:px-5 xl:text-base text-center
      bg-[#f0703a] 
      text-white 
      rounded
      hover:bg-[#cc6235] 
      hover:scale-105 
      transition"
                >
                  Get Started
                </Link>
              ) : (
                <Link
                  className="text-[#cc6235] font-semibold flex space-x-3 px-3 py-2 
      xl:px-5 xl:text-base text-center"
                >
                  Welcome, {user ? user.name : "User"}!
                </Link>
              )}

              <Link
                to="/volunteer"
                className="relative px-3 py-2 
      text-sm  
      xl:px-5 xl:text-base text-center
      border border-[#f0703a] 
      text-[#f0703a] 
      font-semibold
      rounded 
      transition-all duration-300 
      hover:font-bold 
      hover:bg-[#f0703a] hover:text-white 
      hover:scale-105 
      before:content-[''] 
      before:absolute before:inset-0 
      before:rounded 
      before:border-[2px] before:border-[#f0703a] 
      before:animate-glow before:pointer-events-none"
              >
                Volunteer with Us
              </Link>

              {user ? (
                <Link
                  onClick={() => handleLogout()}
                  className=" px-3 py-2 
      text-sm
      
      xl:px-5 xl:text-base
             border border-[#f0703a] 
             text-[#f0703a] 
             rounded 
             transition duration-300 
             hover:bg-[#f0703a] hover:text-white text-center"
                >
                  Logout{" "}
                </Link>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </motion.div>

        {/* Mobile Menu */}

        {mobileMenuOpen && (
          <div>
            <div
              className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-40 overflow-auto"
              onClick={() => {
                setMobileMenuOpen(false);
                setActiveDropdown(null); // Close dropdowns when clicking outside
              }}
            />
            {/* Navbar Content */}
            <div className="lg:hidden bg-white shadow-lg w-2/3 sm:w-1/2 md:w-2/5 h-screen fixed top-0 right-0 z-50">
              <div className="lg:hidden">
                <button
                  onClick={toggleMobileMenu}
                  className="absolute top-8 right-7 text-xl sm:text-2xl md:text-3xl text-[#cc6235] focus:outline-none"
                >
                  {mobileMenuOpen ? <FaTimes /> : <FaBars />}
                </button>
              </div>

              <ul className="flex flex-col space-y-8 md:space-y-10  py-8 px-6 mt-14 md:mt-20">
                {/* Get Started Button */}
                <li className="pt-6">
                  {user ? (
                    <Link
                      className="text-[#cc6235] font-semibold flex space-x-3  lg:px-4 lg:py-1.5 
      xl:px-5 xl:py-2 xl:text-base text-center"
                    >
                      Welcome, {user ? user.name : "User"}!
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li className="text-left">
                  <button
                    onClick={() => toggleDropdown("about")}
                    className="text-[#cc6235] hover:text-[#f0703a]] font-semibold flex items-center w-full"
                  >
                    About Us
                    <FaChevronDown
                      className={`ml-1 transform transition-transform duration-300 ${
                        activeDropdown === "about" ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {activeDropdown === "about" && (
                    <div className="mt-1 text-sm font-semibold">
                      <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Home
                        </span>
                      </Link>
                      <Link to="/team" onClick={() => setMobileMenuOpen(false)}>
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Team
                        </span>
                      </Link>
                      <Link to="/faq" onClick={() => setMobileMenuOpen(false)}>
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          FAQs
                        </span>
                      </Link>
                      <Link
                        to="/contact-us"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Contact Us
                        </span>
                      </Link>
                    </div>
                  )}
                </li>

                {/* Services */}
                <li className="text-left">
                  <button
                    onClick={() => toggleDropdown("services")}
                    className="text-[#cc6235] hover:text-[#f0703a] font-semibold flex items-center w-full"
                  >
                    Services
                    <FaChevronDown
                      className={`ml-1 transform transition-transform duration-300 ${
                        activeDropdown === "services" ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {activeDropdown === "services" && (
                    <div className="mt-1 text-sm font-semibold">
                      <Link
                        to="/depression"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Depression
                        </span>
                      </Link>
                      <Link
                        to="/addiction"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Addiction
                        </span>
                      </Link>
                      <Link
                        to="/anxiety"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Anxiety
                        </span>
                      </Link>
                      <Link to="/ocd" onClick={() => setMobileMenuOpen(false)}>
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          OCD
                        </span>
                      </Link>
                      <Link
                        to="/women-problems"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Women Problems
                        </span>
                      </Link>
                      <Link
                        to="/social-anxiety"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Social Anxiety
                        </span>
                      </Link>
                    </div>
                  )}
                </li>

                {/* Resources */}
                <li className="text-left">
                  <button
                    onClick={() => toggleDropdown("resources")}
                    className="text-[#cc6235] hover:text-[#f0703a] font-semibold flex items-center w-full"
                  >
                    Resources
                    <FaChevronDown
                      className={`ml-1 transform transition-transform duration-300 ${
                        activeDropdown === "resources" ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {activeDropdown === "resources" && (
                    <div className="mt-1 text-sm font-semibold">
                      <Link
                        to="/music"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Music
                        </span>
                      </Link>
                      <Link
                        to="/videos"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Videos
                        </span>
                      </Link>
                      <Link
                        to="/assessments"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Assessments
                        </span>
                      </Link>
                      <button onClick={handleButtons}>
                        <span className="block px-2 py-3 text-gray-600 hover:text-[#f0703a] hover:font-bold">
                          Exercises
                        </span>
                      </button>
                    </div>
                  )}
                </li>

                <li className="text-left">
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    to="/blogs"
                    className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
                  >
                    Blogs
                  </Link>
                </li>

                <li className="text-left">
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    to="/chatbot"
                    className="text-[#cc6235] hover:text-[#f0703a] font-semibold"
                  >
                    Chatbot
                  </Link>
                </li>

                {/* Get Started Button */}
                <li className="pt-6">
                  {user ? (
                    <Link
                      onClick={() => handleLogout()}
                      className="mt-7 relative px-4 py-3 
    text-sm 
    border border-[#f0703a] 
    text-[#f0703a] 
    font-semibold 
    rounded 
    transition-all duration-300 
    hover:font-bold 
    hover:bg-[#f0703a] hover:text-white 
    hover:scale-105 
    before:content-[''] 
    before:absolute before:inset-0 
    before:rounded 
   
    block text-center w-full"
                    >
                      Logout{" "}
                    </Link>
                  ) : (
                    <div>
                      <Link
                        to="/signup"
                        onClick={() => setMobileMenuOpen(false)}
                        className="mt-7 relative px-4 py-3 
    text-sm 
    border border-[#f0703a] 
    text-[#f0703a] 
    font-semibold 
    rounded 
    transition-all duration-300 
    hover:font-bold 
    hover:bg-[#f0703a] hover:text-white 
    hover:scale-105 
    before:content-[''] 
    before:absolute before:inset-0 
    before:rounded 
   
    block text-center w-full"
                      >
                        Get Started{" "}
                      </Link>
                    </div>
                  )}
                  <Link
                    to="/volunteer"
                    onClick={() => setMobileMenuOpen(false)}
                    className="mt-7 relative px-4 py-3 
    text-sm 
    border border-[#f0703a] 
    text-[#f0703a] 
    font-semibold 
    rounded 
    transition-all duration-300 
    hover:font-bold 
    hover:bg-[#f0703a] hover:text-white 
    hover:scale-105 
    before:content-[''] 
    before:absolute before:inset-0 
    before:rounded 
    before:border-[2px] before:border-[#f0703a] 
    before:animate-glow before:pointer-events-none 
    block text-center w-full"
                  >
                    Volunteer with Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
