import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Testimonial = () => {
  const testimonials = [
    {
      name: "Anonymous, Cabin Crew, Delhi",
      text: "I was sceptical and afraid to be open to another person. I thought I could not do it, but [my therapist] has been very supportive in terms of my mental health journey and just allows me to feel my emotions and simplifies them for me.",
    },
    {
      name: "Rahul, Software Engineer, Mumbai",
      text: "The platform has completely changed how I deal with stress. I used to struggle with anxiety, but after engaging in therapy sessions, I feel more at peace. My therapist listens and gives advice that truly works for me.",
    },
    {
      name: "Priya, Marketing Executive, Bangalore",
      text: "I was hesitant at first, but now I can’t imagine how I would have managed without their support. The tools provided here are so practical and helpful, and my therapist is genuinely caring.",
    },
    {
      name: "Ananya, Graphic Designer, Hyderabad",
      text: "What I love about this service is how personal everything feels. I’ve been working on my mental health for years, and this has been the most impactful experience. It’s helped me regain my confidence.",
    },
    {
      name: "Ravi, Business Analyst, Pune",
      text: "Being part of this platform has helped me more than I ever thought possible. The insights I’ve gained and the personalized care have made a world of difference in how I handle work-related stress.",
    },
    {
      name: "Sara, HR Manager, Chennai",
      text: "I cannot recommend this enough. My therapist has guided me through some very dark times, and now I feel much more in control of my emotions. The level of support is phenomenal.",
    },
  ];

  return (
    <div className="w-screen bg-[#fff7e9] py-16 md:py-20 lg:py-24 padding-x">
      {/* Testimonial Section */}
      <div className="mx-auto flex flex-col lg:flex-row justify-between items-center space-y-12 lg:space-y-0">
        {/* Left Side: Heading */}
        <div className="text-center lg:text-left">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800">
            Guiding countless individuals <br />
            to <span className="text-[#f0703a]">emotional wellness.</span>
          </h2>
          <p className="mt-4 text-sm sm:text-lg text-gray-600 lg:w-[70%]">
            Join us and take the first step towards a brighter future.
          </p>
        </div>

        {/* Right Side: Centered Testimonial Slider */}
        <div className="w-full flex justify-center relative h-fit">
          <div className="relative w-full lg:w-3/5 max-w-lg flex items-center justify-center">
            <Swiper
              modules={[Navigation, Autoplay]} // Include Autoplay module
              spaceBetween={100}
              slidesPerView={1}
              navigation={{
                nextEl: ".custom-next-arrow",
                prevEl: ".custom-prev-arrow",
              }}
              autoplay={{
                delay: 2000, // Set delay to 2 seconds
                disableOnInteraction: false, // Allow autoplay even after user interaction
              }}
              loop={true} // Enable infinite loop
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide
                  key={index}
                  className="flex justify-center items-center"
                >
                  <div className="bg-gray-800 p-8 sm:p-8 md:p-12 lg:p-10 lg:px-12 lg:py-12 rounded-3xl text-white text-center lg:min-h-[280px] flex flex-col gap-4 sm:max-w-full">
                    <p className="text-sm sm:text-base">{testimonial.text}</p>
                    <p className="mt-2 font-semibold text-sm sm:text-base">
                      {testimonial.name}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Navigation Arrows */}
            <div className="custom-next-arrow absolute top-1/2 md:-right-20 -right-8 transform -translate-y-1/2 bg-[#f0703a] hover:bg-[#cc6235] p-1 md:p-2.5 rounded-full text-white cursor-pointer z-10">
              <FaArrowRight className="sm:text-lg text-sm" />
            </div>
            <div className="custom-prev-arrow absolute top-1/2 md:-left-20 -left-8 transform -translate-y-1/2 bg-[#f0703a] hover:bg-[#cc6235] p-1 md:p-2.5 rounded-full text-white cursor-pointer z-10">
              <FaArrowLeft className="sm:text-lg text-sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
