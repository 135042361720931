import React from "react";
import logo from "../../assets/Logo1.svg";
import googlePlay from "../../assets/google-play.png"; // Placeholder for Google Play image
import appStore from "../../assets/App-store.png"; // Placeholder for App Store image

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-[#373f61] py-8 border-t font-sora lg:py-16 padding-x">
        <div className="max-w-7xl mx-auto px-4 text-sm lg:text-base">
          <div className="flex flex-col-reverse lg:flex-row justify-between gap-y-12 lg:gap-8">
            {/* About ReZen */}
            <div className="flex flex-col items-center lg:items-start">
              <h4 className="text-white font-semibold mb-4">ABOUT REZEN</h4>
              <ul className="text-center lg:text-left">
                <li className="text-gray-100 mb-2">
                  <Link to="/faq">About Us</Link>
                </li>
                <li className="text-gray-100 mb-2">Careers</li>
                <li className="text-gray-100 mb-2">
                  <Link to="#">Media</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/volunteer">For Therapists</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/faq">FAQs</Link>
                </li>
              </ul>
            </div>

            {/* Services */}
            <div className="flex flex-col items-center lg:items-start">
              <h4 className="text-white font-semibold mb-4">OFFERINGS</h4>
              <ul className="text-center lg:text-left">
                <li className="text-gray-100 mb-2">
                  <a href="https://chat.whatsapp.com/FHud0xMwIdp10qcQIvGcDx">
                    Diagnosis and Therapy
                  </a>
                </li>
                <a href="/app-prod-release.apk" download="app-prod-release.apk">
                  <li className="text-gray-100 mb-2">Self-care and Progress</li>
                </a>
                <li className="text-gray-100 mb-2">
                  <a href="https://chat.whatsapp.com/FHud0xMwIdp10qcQIvGcDx">
                    Community
                  </a>
                </li>
              </ul>
            </div>

            {/* Conditions */}
            <div className="flex flex-col items-center lg:items-start">
              <h4 className="text-white font-semibold mb-4">SERVICES</h4>
              <ul className="text-center lg:text-left">
                <li className="text-gray-100 mb-2">
                  <Link to="/depression">Depression</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="addiction">Addiction</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/anxiety">Anxiety</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/ocd">OCD</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/women-problems">Women Problem</Link>
                </li>
                <li className="text-gray-100 mb-2">
                  <Link to="/social-anxiety">Social Anxiety</Link>
                </li>
              </ul>
            </div>

            {/* Logo, Copyright, and App Buttons */}
            <div className="flex flex-col justify-center items-center lg:items-start">
              {/* Logo */}
              <img
                src={logo}
                alt="ReZen Logo"
                className="mb-4 h-10 lg:h-14 w-auto"
              />
              <p className="text-gray-100 text-center lg:text-left mb-4">
                &copy; 2024 ReZen. All rights reserved.
              </p>

              {/* App Store and Google Play Buttons */}
              <div className="flex space-x-4">
                <a href="/app-prod-release.apk" download="app-prod-release.apk">
                  <img
                    src={googlePlay}
                    alt="Download on Google Play"
                    className="h-11 w-auto"
                  />
                </a>
                <a href="/app-prod-release.apk" download="app-prod-release.apk">
                  <img
                    src={appStore}
                    alt="Download on App Store"
                    className="h-12 w-auto"
                  />
                </a>
              </div>
              <div className="flex space-x-4">
                {/* Privacy Policy */}
                <Link
                  to="/privacy-policy"
                  className="text-gray-200 text-xs mt-2"
                >
                  <button className="hover:underline">Privacy Policy</button>
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="text-gray-200 text-xs mt-2"
                >
                  <button className="hover:underline">
                    Terms & Conditions
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
