import React from "react";
import Gaurav from "../../assets/Gaurav.jpeg";
import Ishaan from "../../assets/Ishaan.jpeg";
import Saurav from "../../assets/Saurav.png";
import Yash from "../../assets/Yash.jpg";

const teamMembers = [
  {
    name: "Gaurav Kumar",
    email: "gaurv1407@gmail.com",
    photo: Gaurav,
  },
  {
    name: "Ishaan Dwivedi",
    email: "ishaan.dwivedi99@gmail.com",
    photo: Ishaan,
  },
  {
    name: "Saurav Kumar",
    email: "krsaurav2208@gmail.com",
    photo: Saurav,
  },
  {
    name: "Yash Tripathi",
    email: "tripathiyash1004@gmail.com",
    photo: Yash,
  },
];

const TeamPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#f6f2e4] via-[#f3cca7] to-[#f4b063] flex flex-col items-center py-24 md:py-32 font-sora padding-x">
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 text-center mb-10 md:mb-16 tracking-wide leading-tight">
        Meet Our <span className="text-[#f0703a]">Incredible Team</span>
      </h2>

      {/* Team Members */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-16 xl:gap-6 py-8 w-full">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="group bg-white rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl duration-300 w-64 sm:w-72 h-[300px] md:h-[420px] mx-auto cursor-pointer"
          >
            {/* Member Photo */}
            <div className="w-full h-3/4">
              <img
                src={member.photo}
                alt={member.name}
                className="w-full h-full rounded-t-lg object-cover group-hover:scale-105 transition-transform duration-500"
              />
            </div>
            {/* Member Details */}
            <div className="p-5 flex flex-col items-center justify-center h-1/4">
              <h3 className="text-lg md:text-xl font-semibold text-[#333] mb-1 group-hover:text-[#f0703a] transition-colors duration-300">
                {member.name}
              </h3>
              <p className="text-sm text-gray-600">{member.email}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
